button{
	cursor: pointer;
}

@each $colorName, $colorValue in $colors{
	.button--#{$colorName}{
		@include transition(all);
		padding: rem-calc(18 20);
		font-size: rem-calc(20);
		font-weight: 500;
		border-radius: rem-calc(10);
		display: inline-block;
		@if $colorName == 'white' {
			border: 2px solid white;
			background:white;
			color: $primary-color !important;
		}@else {
			background:$colorValue;
			border: 2px solid $colorValue;
			color:$white;
		}
		&:hover,&:focus{
			background:transparent;
			@if $colorName == 'white' {
				background:$primary-color;
				color: white !important;
			}@else {
				color: $primary-color;
			}
		}
		&.hollow {
			background: transparent !important;
			color: $colorValue;
			&:hover,&:focus {
				background:white !important;
				border-color: $colorValue;
				color: $primary-color;
			}
		}
	}
}
