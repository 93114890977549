$spacers: (
		'10' : 10,
		'20' : 20,
		'30' : 30,
		'40' : 40,
		'50' : 50,
		'75' : 75,
		'100' : 100,
		'200' : 200,
);
@each $spacerSize, $spacerValue in $spacers {
	.spacer--#{$spacerSize}-px{
		height: calc(#{$spacerValue}px / 2);
		@include breakpoint(medium) {
			height: #{$spacerValue}px;
		}
	}
	.spacer--#{$spacerSize}-vh{
		height: #{$spacerValue}vh;
	}
}
$spacersTextLarge: (
		'xx-large' : 300,
		'x-large' : 200,
		'large' : 100,
		'medium' : 75,
		'small' : 50,
);
$spacersTextSmall: (
		'xx-large' : 75,
		'x-large' : 50,
		'large' : 30,
		'medium' : 20,
		'small' : 10,
);
@each $spacerSize, $spacerValue in $spacersTextSmall {
	.spacer--#{$spacerSize} {
		height: #{$spacerValue}px;
	}
}
@include breakpoint(large) {
	@each $spacerSize, $spacerValue in $spacersTextLarge {
		.spacer--#{$spacerSize} {
			height: #{$spacerValue}px;
		}
	}
}

.container-spacer{
	height: rem-calc(200);
}
