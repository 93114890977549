.image-list{
	a{
		display: block;
		@include aspect-ratio(16,9);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: rem-calc(20);
	}
	.cell{
		margin-bottom: rem-calc(30);
	}
}
