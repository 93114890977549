.full-carousel{
	height: 100vh;
	&__slide{
		@include cover-bg;
		height: 100vh;
		position: relative;
		.grid-container{
			position: relative;
			z-index: 5;
			height: 100%;
			.grid-x{
				@extend .align-middle;
				height: 100%;
			}
		}

		@include breakpoint(medium){
			height: 50px;
		}
		@include text-spects{
			color:$white;
		}
	}
	&__pagination{
		@include breakpoint(medium){
			height: 50px;
		}
		@include horizontal-center;
		bottom:rem-calc(20px);
		list-style-type: none;
		li,span{
			margin:rem-calc(0 5px);
			display: inline-block;
			cursor: pointer;
		}
	}
}