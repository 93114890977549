.main-menu{
	$p: &;
	@include transition(background);
	z-index: 50;
	position: fixed;
	padding:rem-calc(0 15);
	@include breakpoint(large){
		padding:rem-calc(0 50);
	}
	top: 0;
	left: 0;
	right: 0;
	.logo{
		display: inline-block;
	}
	svg{
		@include transition(all);
		width: rem-calc(150);
		@include breakpoint(medium) {
			width: rem-calc(195);
		}
	}
	&__nav{
		margin:0;
		a{
			font-size: rem-calc(14);
			font-weight: 700;
			padding:rem-calc(39 0 35);
			display: inline-block;
			border-top:4px solid transparent;
		}
		li{
			list-style-type: none;
			display: inline-block;
			&::before{
				display: none;
			}
			+li{
				margin-left: rem-calc(30);
			}
			&:hover{
				a{
					color:$primary-color;
				}
			}
			&.is-active{
				a{
					border-top:4px solid $primary-color;
					color:$primary-color;
				}
			}
		}
	}
	&.has-scrolled{
		background:white;
		.logo{
			&.logo--light{
				svg{
					.st0{fill:$secondary-color;}
					.st1{fill:$primary-color;}
				}
			}
		}
		#{$p}__nav{
			a{
				color: $secondary-color;
			}
			li{
				&.is-active{
					a{
						border-top:4px solid $primary-color;
						color:$primary-color;
					}
				}
				&:hover{
					a{
						color:$primary-color;
					}
				}
			}
		}
	}
	&--light{
		a {
			color: white;
		}
		svg{
			.st0{fill:white;}
			.st1{fill:$primary-color;}
		}
		&.has-scrolled{
			svg{
				.st0{fill:$secondary-color;}
				.st1{fill:$primary-color;}
			}
		}
	}
	&--dark{
		a{
			color:$secondary-color;
		}
		svg{
			.st0{fill:$secondary-color;}
			.st1{fill:$primary-color;}
		}
		.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
			background-color: $secondary-color;
		}
	}
}
