footer{
	background:$body-background;
	padding:rem-calc(73 0);
	border-top:1px solid #DDDDDD;
	h1,h2,h3,h4,h5,h6,p{
		color:#5E7177;
	}
	.cell{
		margin-bottom: rem-calc(30);
		&:last-child{
			margin-bottom: 0;
		}
		@include breakpoint(medium){
			margin-bottom: 0;
		}
	}
}
