.callout{
	&.primary{
		background:$primary-color;
		*{
			color:white;
		}
		button{
			display: block;
			width: 100%;
		}
		form{
			@extend .grid-x;
			@extend .grid-margin-x;
			@include breakpoint(small only){
				.cell{
					width: calc(100% - 1.875rem) !important;
				}
			}
			@include breakpoint(medium){
				.form-group{
					margin-bottom: 0;
				}
			}
			input{
				background:transparent;
				color:white;
				border:2px solid white;
				@include placeholder{
					color:white;
				}
				&[data-invalid]{
					animation-name: shake;
					animation-duration: 0.4s;
					transform-origin: 50% 50%;
					animation-iteration-count: 1;
					animation-timing-function: linear;
					border-color: darken($primary-color,20%);
				}
			}
		}
	}
}

@-webkit-keyframes shake {
	0% {
		transform: translate(2px, 1px);
	}
	15% {
		transform: translate(-1px, 2px);
	}
	30% {
		transform: translate(-3px, 1px);
	}
	55% {
		transform: translate(2px, 1px);
	}
	70% {
		transform: translate(-1px, -1px);
	}
	85% {
		transform: translate(2px, 2px);
	}
	100% {
		transform: translate(1px, -2px);
	}
}
