.full-width-banner{
	position: relative;
	background-position: center;
	background-size:cover;
	height: rem-calc(600);
	@include breakpoint(medium){
		height: rem-calc(800);
	}
	.grid-container,
	.grid-x{
		height: 100%;
	}
	&__caption{
		background:rgba(#FFFFFF,.9);
		padding:rem-calc(30);
		border-radius: rem-calc(20);
		@include breakpoint(large) {
			padding: rem-calc(40);
		}
		p{
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	a[href*="#"]{
		margin:rem-calc(30 0 0);
		display: block;
		font-weight: 400;
		font-size: rem-calc(20);
	}
	&::before{
		position: absolute;
		top: rem-calc(0);
		left: 0;
		width: 100%;
		content: '';
		height: rem-calc(150);
		z-index: 1;
		background: -moz-linear-gradient(top, rgba($secondary-color,.85) 0%, rgba(#ffffff,0) 100%);
		background: -webkit-linear-gradient(top, rgba($secondary-color,.85) 0%,rgba(#ffffff,0) 100%);
		background: linear-gradient(to bottom, rgba($secondary-color,.85) 0%,rgba(#ffffff,0) 100%);
	}
	&::after{
		position: absolute;
		bottom: rem-calc(0);
		left: 0;
		width: 100%;
		content: '';
		height: rem-calc(20);
		background:$body-background;
		z-index: 1;
		border-radius: rem-calc(50 50 0 0);
	}
}
