//Sticky footer
.page{
	&__container{
		min-height: 100vh;
		display: flex;
		flex-direction: column;
	}
	&__content{
		flex: 1 0 auto;
	}
	&__inner{
		padding: rem-calc(20 0 0);
		@include breakpoint(medium){
			padding: rem-calc(50 0 0);
		}
		@include breakpoint(large){
			padding: rem-calc(100 0 0);
		}
	}
}

footer {
	flex-shrink: 0;
}
