.is-off-canvas-open{
	.logo{
		svg{
			.st0{fill:$secondary-color;}
			.st1{fill:$primary-color;}
		}
	}
	.main-menu{
		background:$body-background;
		&.has-scrolled{
			background:$body-background;
		}
		&--dark{
			.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
				color:$secondary-color;
			}
		}
		&--light{
			.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
				background:$secondary-color;
			}
		}
	}
}

.off-canvas{
	.inner{
		padding-top:100px;
	}
	ul{
		margin:0;
		list-style-type: 0;
		li{
			margin:0;
			padding:0;
			border-top:1px solid #DDDDDD;
			&:last-child {
				border-bottom: 1px solid #DDDDDD;
			}
			&.active{
				a{
					color: $primary-color;
					font-weight: 500;
				}
			}
		}
		a{
			font-weight: 300;
			display: block;
			width: 100%;
			color: $secondary-color;
			padding:rem-calc(15 20);
		}
	}
}

.menu-button{
	width:50px !important;
	height:50px !important;
	display:inline-block;
	transition: all 150ms cubic-bezier(0.645, 0.045, 0.355, 1) !important;
	transform: translateY(0px);
	&[aria-expanded="true"]{
		.hamburger-inner {
			transform: translate3d(0, 7px, 0) rotate(45deg);
			&::before {
				transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
				opacity: 0;
			}
			&::after {
				top:rem-calc(11);
				transform: translate3d(0, -11px, 0) rotate(-90deg);
			}
		}
	}
}

.hamburger {
	width:100%;
	height:100%;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	overflow: visible;
	.hamburger-box {
		width: 25px;
		height: 16px;
		display: inline-block;
		position: relative;
		left:13px;
		top: 16px;
		.hamburger-inner {
			display: block;
			top:0;
		}
	}
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
	width: 25px;
	height: 2px;
	background-color: $white;
	position: absolute;
	transition-property: all;
	transition-duration: 0.15s;
	transition-timing-function: ease-in-out;
}
.hamburger-inner::before, .hamburger-inner::after {
	content: "";
	display: block;
}
.hamburger-inner::before {
	top: -rem-calc(10);
}
.hamburger-inner::after {
	bottom: -rem-calc(10);
}
.hamburger--slider{
	.hamburger-inner {

		&::before {
			top: 8px;
			transition-property: transform, opacity;
			transition-timing-function: ease;
			transition-duration: 0.2s;
		}
		&::after {
			top: 16px;
		}
	}

}

.main-menu{
	&.has-scrolled{
		.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
			background-color: $secondary-color;
		}
	}
}
