[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{
	margin-bottom: 0;
	height: 60px;
	padding: rem-calc(20);
	resize: vertical;
}

textarea{
	height: rem-calc(150);
}

form{
	button{
		@include breakpoint(small only){
			width: 100%;
		}
	}
}

.form-group{
	margin-bottom: rem-calc(20px);
	label{
		display: none;
	}
}

.block__renatioform {
	.form__outer-container {

	}
}
.form-control.valid {
	border-color: $primary-color;
}
form.sending {
	.form-group {
		opacity: 0.5;
	}
}
.form__container {
	position: relative;
	z-index: 0;
	.form__loader {
		z-index: -1;
		opacity: 0;
		background-color: rgba($white, 0.7);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		@include transition(all);
		svg {
			width: 50px;
			height: 50px;
			@include horizontal-center();
			top: 30%;
		}
	}
	&.sending {
		.form__loader {
			z-index: 1;
			opacity: 1;
		}
	}
}
