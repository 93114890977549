.route-header{
	&__caption{
		background:#FFFFFF;
		padding:rem-calc(30);
		border-radius: rem-calc(20);
		@include breakpoint(large) {
			padding: rem-calc(50);
		}
		p{
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	ul{
		margin:rem-calc(10 0 80);
		padding:0;
		list-style: none;
		li{
			font-weight: 500;
			font-size: rem-calc(20);
			color:#5E7177;
			i{
				margin-right:rem-calc(15);
				svg{
					position: relative;
					top: rem-calc(3);
					width: rem-calc(20);
				}
			}
		}
	}
	a[href*="#"]{
		margin:rem-calc(30 0 0);
		display: block;
		font-weight: 400;
		font-size: rem-calc(20);
	}
	&__image{
		background-size: cover;
		background-position: center;
		border-radius: rem-calc(20);
		@include breakpoint(small only){
			@include aspect-ratio(16,9);
			margin-top:rem-calc(20);
			margin-left: .9375rem;
			margin-right: .9375rem;
		}
	}
}
