.page-header{
	border-radius: rem-calc(20);
	padding:rem-calc(90 0);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	&__caption{
		background:rgba(#FFFFFF,.9);
		padding:rem-calc(30);
		border-radius: rem-calc(20);
		@include breakpoint(medium) {
			padding:rem-calc(30);
		}
		@include breakpoint(large) {
			padding:rem-calc(50);
			background: #FFFFFF;
		}
		p{
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	ul{
		margin:rem-calc(10 0 80);
		padding:0;
		list-style: none;
		li{
			font-weight: 500;
			font-size: rem-calc(20);
			color:#5E7177;
			i{
				margin-right:rem-calc(15);
			}
		}
	}
	a[href*="#"]{
		margin:rem-calc(30 0 0);
		display: block;
		font-weight: 400;
		font-size: rem-calc(20);
	}
}
