.social-list{
	margin:0;
	li{
		display: inline-block;
		+li{
			margin-left: rem-calc(20);
		}
	}
	a{
		svg{
			.a{
				@include transition(all);
				fill: #5e7177;
			}
		}
		&:hover{
			svg{
				.a {
					fill: $primary-color;
				}
			}
		}
	}
}
