.card{
	border-radius:rem-calc(20);
	&[href]{
		@include transition(all);
	}
	&__image{
		@include aspect-ratio(16,9);
		background-size: cover;
		background-position: center;
	}
    small{
        margin-bottom: 4px;
        display: block;
    }
	&-section{
		h4{
			font-size: rem-calc(20);
			i{
				position: relative;
				top: rem-calc(2);
				margin-right: rem-calc(10);
			}

		}
		p{
			font-size: rem-calc(18);
			color:$body-font-color;
			span{
				@extend .weight--medium;
				color:$primary-color;
				font-weight: rem-calc(20);
			}
		}
	}
	&:hover[href]{
		box-shadow:0 0 15px rgba(#000000,.25);
	}
}
