@import "full-width-banner";
@import "page-header";
@import "route-header";

header:not(.full-width-banner){
	padding: rem-calc(100 15 0);
	@include breakpoint(small only) {
		margin-bottom: 20px;
	}
	@include breakpoint(large) {
		padding: rem-calc(100 30 0);
	}
}
