@import "backgrounds";
@import "textcolors";
@import "blockquote";
@import 'border';
@import 'buttons';
@import "content";
@import "colors";
@import 'forms';
@import 'headers';
@import "helpers";
@import "icons";
@import 'image';
@import "links";
@import 'lists';
@import 'paragraph';
@import "ruler";

body{
	overflow-x: hidden;
}
.show-for-admin {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: #ff0000;
		opacity: 0.2;
		pointer-events: none;
	}
	&:after {
		content: 'Only visible for admins!';
		top: 0;
		left: 0;
		background: rgba(255,0,0,0.7);
		color: white;
		border: 1px solid #ff0000;
		box-shadow: 0 4px 5px rgba(0,0,0,0.2);
		position: absolute;
	}
}
