$primary-color: #69df89;
$secondary-color: #032a38;
$white: #FFFFFF;
$black: #000000;
$colors: (
		'primary' : $primary-color,
		'secondary' : $secondary-color,
		'white' : $white,
		'black' : $black,
);
