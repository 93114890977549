.usp-list{
	.cell{
		margin-bottom: rem-calc(50);
		text-align: center;
		&:last-child{
			margin-bottom: 0;
		}
		@include breakpoint(medium){
			&:nth-last-of-type(-n+2){
				margin-bottom: 0;
			}
		}
		@include breakpoint(large){
			margin-bottom: 0;
		}
		p{
			margin-bottom: 0;
		}
	}
}
